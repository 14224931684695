import React from 'react'
import { Wrap } from '../components/page/components'
import { graphql } from 'gatsby'
import TopicList from '../components/graphic-design/topic-list'
import { compose } from 'recompose'
import { withAuthorization } from '../components/session'
import SEO from '../components/SEO'
import AppHeader from '../components/global/app-header'
import AppBody from '../components/page/app-body'
import AppMenu from '../components/page/app-menu'
import validateUser from '../utils/validateUser'
const GraphicDesignBase = ({ data, authUser, location }) => {
  const { topics, subtopics } = data.contentfulCourse
  validateUser(authUser, document.referrer)

  return (
    <Wrap>
      <SEO title="Graphic Design" />
      <AppHeader
        title="Graphic Design"
        authUser={authUser}
        searchDefault="graphic-design"
        backUrl="/graphic-design"
      />
      <AppBody>
        <TopicList
          topics={topics}
          subtopics={subtopics}
          urlBase="/graphic-design"
        />
      </AppBody>
      <AppMenu pathname={location.pathname} authUser={authUser} />
    </Wrap>
  )
}

export const query = graphql`
  query {
    contentfulCourse(slug: { eq: "graphic-design-course" }) {
      topics {
        title
        slug
      }
      subtopics {
        title
        id
        slug
        releaseTiming
        publishDate
        code
        status
        isActive
        topic {
          id
          slug
          title
        }
      }
    }
  }
`

const condition = authUser => !!authUser
const GraphicDesign = compose(withAuthorization(condition))(GraphicDesignBase)
export default GraphicDesign
